
$(document).ready(function(){
  console.log("ready!!")


  $("#post_post_type").change(function(){
      var post_type = $(this).val()
      if (post_type == "place"){
        $("#pricing").hide()
      } else {
        $("#pricing").show();
      }
  })

  $('#post_address').keyup(delay(function (e) {
    var address = this.value
    var town = $( "#post_town_id option:selected" ).text();



    address = address+","+town

    console.log("address",address)
    console.log("town", town)

    $("#options").empty()
    $("#okaddress").hide()
    $("#post_latitude").val("")
    $("#post_longitude").val("")

    $.get("/geocode_endpoint?address="+address, function(data, status){
        console.log("results",data.results)

        if (data.results == undefined){
          $("#options").append("<p><b> No se han encontrado resultados para tu dirección. </b></p>")
          return
        }

        if (data.results.length > 0){

          $("#options").append("<span style='margin-top: 15px;'><b>Elige una dirección de la lista: </b></span>")


        } else {
            $("#options").append("<p><b> No se han encontrado resultados para tu dirección. </b></p>")
        }

        $.each(data.results, function(idx, result){ 
            var append = "<p style='cursor: pointer;' class='mt-1 alert alert-warning place' data-latitude='"+result.position.lat+"' data-address='"+removeSpecialCharacters(result.address.freeformAddress)+"' data-longitude='"+result.position.lon+"'>"+result.address.freeformAddress+", "+result.address.countrySecondarySubdivision+"</p>"
            $("#options").append(append)
        })


    });
    

  }, 300));


  // Function similar tu ruby on rails parameterize.
  // Replaces accents and special characters in a string with their equivalents.
  function removeSpecialCharacters(string){

    /*var accents    = 'ÀÁÂÃÄÅàáâãäåÈÉÊËèéêëÌÍÎÏìíîïÒÓÔÕÕÖØòóôõöøÙÚÛÜùúûüÿÑñçÇ';
    var accentsOut = "AAAAAAaaaaaaEEEEeeeeIIIIiiiiOOOOOOOoooooooUUUUuuuuyNncC";

    string = string.split('');
    var strLen = string.length;
    var i, x;

    for (i = 0; i < strLen; i++) {
      if ((x = accents.indexOf(string[i])) != -1) {
        string[i] = accentsOut[x];
      }
    }

    // Remove ' and " from string
    
    */
    string = string.replace(/['"]+/g, '');
    console.log("string is ", string)
    return string

  }


  $(document).on('click', '.place', function(){
    var lat = $(this).attr("data-latitude")
    var lon = $(this).attr("data-longitude")
    var address = $(this).attr("data-address")

    console.log("click address", address)

    $("#post_latitude").val(lat)
    $("#post_longitude").val(lon)
    $("#post_address").val(address)
    $("#options").empty()
    $("#okaddress").show()

  })

  function delay(callback, ms) {
    var timer = 0;
    return function() {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }
  

})